<template>
  <div class="flex flex-col items-center gap-y-40">
    <div
      class="flex flex-col items-center justify-center max-w-full"
      @touchstart.stop
      @touchend.stop
    >
      <contacts-title
        ref="contactsTitle"
        class="pt-8"
        :name="name"
        :personal="personal"
        :links="links"
      />
      <description class="pt-8 text-sm" />
    </div>
    <mobile-talents-list ref="talents" @touchstart.stop @touchend.stop />
    <mobile-technologies-stacks ref="stacks" @touchstart.stop @touchend.stop />
    <mobile-companies-list ref="companies" :companies="companies" @touchstart.stop @touchend.stop />
    <mobile-feedbacks-list ref="feedbacks" :feedbacks="feedbacks" @touchstart.stop @touchend.stop />
    <mobile-personal-projects ref="projects" @touchstart.stop @touchend.stop />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ContactsTitle from "@/components/ContactsTitle.vue";
import Description from "@/components/Description.vue";
import MobileTalentsList from "@/components/MobileTalentsList.vue";
import MobileCompaniesList from "@/components/MobileCompaniesList.vue";
import MobileFeedbacksList from "@/components/MobileFeedbacksList.vue";
import MobilePersonalProjects from "@/components/MobilePersonalProjects.vue";
import MobileTechnologiesStacks from "@/components/MobileTechnologiesStacks.vue";
import { useAboutStore } from "@/store/about/about";
import { mapState } from "pinia";

export default defineComponent({
  name: "MobileMain",
  components: {
    ContactsTitle,
    Description,
    MobileTalentsList,
    MobileCompaniesList,
    MobileFeedbacksList,
    MobilePersonalProjects,
    MobileTechnologiesStacks,
  },
  computed: {
    ...mapState(useAboutStore, ["personal", "links", "name", "companies", "feedbacks"]),
  },
});
</script>
