<template>
  <div class="flex flex-col items-center justify-center gap-y-8">
    <h3 class="text-2xl font-bold">Tools I use</h3>
    <p class="text-sm -mt-4">
      Visit <a class="ref-in-paragraph" href="/#/tech">technologies</a> for detailed info
    </p>
    <div class="flex flex-row items-start justify-center gap-x-8">
      <tech-stack-card :ref="stack.name" v-for="stack in stacks" :key="stack" :stack="stack" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useTechnologiesStore } from "@/store/technologies/index";
import { mapState } from "pinia";
import TechStackCard from "./TechStackCard.vue";

export default defineComponent({
  name: "TechnologiesStack",
  components: { TechStackCard },
  computed: {
    ...mapState(useTechnologiesStore, ["stacks"]),
  },
});
</script>
