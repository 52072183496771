<template>
  <div class="flex flex-col items-center justify-center max-w-full">
    <h3 class="text-2xl font-bold max-w-fit">Personal Projects</h3>
    <div class="flex flex-col items-center justify-center pt-4">
      <template v-for="(project, index) in personal" :key="index">
        <a :href="project.link">
          <h2>{{ project.name }}</h2>
        </a>
        <img class="pt-2 rounded-xl min-w-[350px] max-w-[350px]" :src="project.gif" />
        <pre class="text-sm text-justify text-wrap max-w-xs pb-20">{{ project.description }}</pre>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useProjectsStore } from "@/store/projects/projects";
import { mapState } from "pinia";

export default defineComponent({
  name: "MobilePersonalProjects",
  computed: {
    ...mapState(useProjectsStore, ["personal"]),
  },
});
</script>

<style scoped>
* {
  @apply font-custom;
}
a {
  @apply flex text-blue-600 hover:underline;
}
img {
  @apply max-w-60 inline mb-2;
}
h2 {
  @apply text-2xl font-bold;
}
</style>
