import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1351dc3a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "block w-auto" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "text-left text-wrap max-w-2xl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projects, (project, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "flex flex-row justify-center gap-x-20",
        key: index
      }, [
        _createElementVNode("img", {
          class: "min-w-[500px] max-w-[500px] rounded-xl",
          src: project.gif
        }, null, 8, _hoisted_2),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("a", {
            href: project.link
          }, [
            _createElementVNode("h2", null, _toDisplayString(project.name), 1)
          ], 8, _hoisted_4),
          _createElementVNode("pre", _hoisted_5, _toDisplayString(project.description), 1)
        ])
      ]))
    }), 128))
  ]))
}