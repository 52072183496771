<template>
  <div class="flex flex-col items-center justify-center">
    <h3 class="mb-4 text-2xl font-bold max-w-fit">Companies I worked with</h3>
    <div class="flex flex-row gap-x-4 max-w-full px-4 items-center">
      <list-button
        @click="handleManualScroll(-manualScrollDelta)"
        :disabled="0 === currentCompany"
      />
      <div
        class="flex flex-row gap-2 border-zinc-600 max-w-[200px] overflow-x-hidden"
        @wheel.prevent="handleScroll($event)"
        @touchstart="handleTouchStart"
        @touchend="handleTouchEnd"
      >
        <company-card
          :ref="index"
          v-for="(company, index) in companies"
          :key="company"
          :info="company"
        />
      </div>
      <list-button
        @click="handleManualScroll(manualScrollDelta)"
        :disabled="currentCompany === Object.keys(this.$refs).length - 1"
        :direction="'right'"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import CompanyCard from "@/components/CompanyCard.vue";
import { Company } from "@/store/about/company";
import ListButton from "./ListButton.vue";

export default defineComponent({
  name: "MobileCompaniesList",
  components: { CompanyCard, ListButton },
  props: { companies: Array as PropType<Array<Company>> },
  data: function () {
    return { manualScrollDelta: 250, currentCompany: 0, scrollStart: 0, scrollEnd: 0 };
  },
  methods: {
    handleTouchStart: function (this: any, event: TouchEvent) {
      this.scrollStart = event.changedTouches[0].pageX;
    },
    handleTouchEnd: function (this: any, event: TouchEvent) {
      this.scrollEnd = event.changedTouches[0].pageX;
      const deltaY = this.scrollStart - this.scrollEnd;
      if (deltaY > 15 || deltaY < -15) this.handleScroll({ deltaY });
    },
    handleScroll: function (this: any, event: any) {
      const companies = Object.keys(this.$refs);
      this.currentCompany += event.deltaY > 0 ? 1 : -1;
      this.currentCompany = this.currentCompany < 0 ? 0 : this.currentCompany;
      this.currentCompany =
        companies.length === this.currentCompany ? this.currentCompany - 1 : this.currentCompany;
      this.$refs[companies[this.currentCompany]][0].$el.scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "nearest",
      });
    },
    handleManualScroll: function (this: any, delta: number) {
      this.handleScroll({ deltaY: delta });
    },
  },
});
</script>
