import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6b65fac4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "border-2 border-zinc-500" }
const _hoisted_3 = {
  class: "section_header",
  colspan: "4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", null, [
      _createElementVNode("tr", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header) => {
          return (_openBlock(), _createElementBlock("th", {
            class: "border border-zinc-500",
            key: header
          }, _toDisplayString(header), 1))
        }), 128))
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.techs, ([section, data], index) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
          _createElementVNode("tr", null, [
            _createElementVNode("th", _hoisted_3, _toDisplayString(_ctx.toCapitalCase(section)), 1)
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data, (t) => {
            return (_openBlock(), _createElementBlock("tr", {
              class: "skill_row",
              key: t
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values(_ctx.prepare(t)), (v) => {
                return (_openBlock(), _createElementBlock("td", {
                  class: "border-x border-zinc-500",
                  key: v
                }, _toDisplayString(v), 1))
              }), 128))
            ]))
          }), 128))
        ], 64))
      }), 128))
    ])
  ]))
}