import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-y-4 bg-white rounded-xl max-h-fit min-w-60 max-w-60" }
const _hoisted_2 = { class: "pt-2 text-2xl font-bold" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "text-base" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.stack.name), 1),
    _createElementVNode("div", {
      class: _normalizeClass(
        _ctx.stack.technologies.length < 6
          ? 'grid grid-cols-1 grid-rows-6'
          : 'grid grid-cols-2 grid-rows-6 gap-x-4'
      )
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stack.technologies, (t) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "flex flex-col items-center p-2",
          key: t
        }, [
          _createElementVNode("img", {
            class: "min-w-[32px]",
            src: _ctx.iconPath(t.icon),
            width: "32px",
            height: "32px"
          }, null, 8, _hoisted_3),
          _createElementVNode("p", _hoisted_4, _toDisplayString(t.name), 1)
        ]))
      }), 128))
    ], 2)
  ]))
}