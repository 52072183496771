<template>
  <div class="flex flex-col">
    <table>
      <tr class="border-2 border-zinc-500">
        <th class="border border-zinc-500" v-for="header in headers" :key="header">{{ header }}</th>
      </tr>
      <template v-for="([section, data], index) in techs" :key="index">
        <tr>
          <th class="section_header" colspan="4">
            {{ toCapitalCase(section) }}
          </th>
        </tr>
        <tr class="skill_row" v-for="t in data" :key="t">
          <td class="border-x border-zinc-500" v-for="v in Object.values(prepare(t))" :key="v">
            {{ v }}
          </td>
        </tr>
      </template>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Technology } from "@/store/technologies/technology";

interface PreparedTechnology {
  name: string;
  experience: string;
  lastUsedYear: number;
  additional: string;
}

export default defineComponent({
  name: "TechnologiesTable",
  props: {
    headers: { type: Array as PropType<string[]>, required: true },
    technologies: { type: Object, required: true },
    searched: { type: String, required: true },
    fuzzy: { type: Boolean, required: true },
    matchCase: { type: Boolean, required: true },
  },
  computed: {
    searchedTechs(): [string, any][] {
      if (!this.searched) {
        return [];
      }

      const search = this.searched;
      const fuzzy = this.fuzzy;
      const matchCase = this.matchCase;
      const searched: Record<string, unknown> = {};
      for (const [section, data] of Object.entries(this.technologies)) {
        const filteredData = data.filter((t: Technology): boolean => {
          const name = matchCase ? t.name : t.name.toLowerCase();
          return fuzzy
            ? name.includes(matchCase ? search : search.toLowerCase())
            : name.startsWith(matchCase ? search : search.toLowerCase());
        });
        if (filteredData.length) {
          searched[section] = filteredData;
        }
      }

      return Object.entries(searched);
    },
    techs(): [string, any][] {
      return this.searchedTechs.length > 0 ? this.searchedTechs : Object.entries(this.technologies);
    },
  },

  methods: {
    toCapitalCase: (el: string): string => {
      const separated = el.replace(/([A-Z])/g, " $1");
      return separated.charAt(0).toUpperCase() + separated.slice(1);
    },
    prepare: (t: Technology): PreparedTechnology => {
      const { name, started, lastUsed, additional } = t;

      let startedDate: Date = new Date();
      startedDate.setFullYear(started);

      let lastUsedDate: Date = new Date();
      if (lastUsed !== "Now") {
        lastUsedDate.setFullYear(Number(lastUsed));
      }

      // in months
      const experience: Date = new Date();
      experience.setFullYear(lastUsedDate.getFullYear() - started);
      experience.setMonth(lastUsedDate.getMonth());

      return {
        name,
        experience:
          experience.getFullYear() === 0
            ? `${experience.getMonth() + 1} months`
            : `${experience.getFullYear()} years ${experience.getMonth() + 1} months`,
        lastUsedYear: lastUsedDate.getFullYear(),
        additional,
      };
    },
  },
});
</script>

<style scoped>
table {
  @apply m-10 
    table-fixed 
    border-collapse 
    border-2 
    border-zinc-500;
}

.section_header {
  @apply text-center 
    py-4 
    border-y border-zinc-500;
}

.skill_row {
  @apply border-transparent
  transition
  duration-300
  ease-in-out

  hover:border
  hover:border-zinc-500
  hover:font-bold;
}
</style>
