<template>
  <div class="flex flex-col gap-y-4 items-center justify-center">
    <h3 class="text-2xl font-bold max-w-fit">What I can do for you</h3>
    <div class="flex flex-row gap-x-2 max-w-full px-4 items-center">
      <list-button
        @click="handleManualScroll(-manualScrollDelta)"
        :disabled="0 === currentTalent"
      />
      <div
        ref="talents"
        class="flex flex-row gap-x-10 max-w-60 min-w-60 border-zinc-600 overflow-x-hidden"
        @touchstart="handleTouchStart"
        @touchend="handleTouchEnd"
      >
        <talent-card
          class="snap-start"
          ref="cloud"
          :img="iconPath('3clouds.webp')"
          talent=" Cloud Applications and adjacent infrastructure(databases, pipelines, message queues, key vaults, kubernetes cluster, etc.) "
        />
        <talent-card
          ref="architecture"
          :img="iconPath('microservices.webp')"
          talent="Monolith and microservices architecture"
        />
        <talent-card ref="webapp" :img="iconPath('webapp.png')" talent="Web Applications" />
        <talent-card
          ref="desktopapp"
          :img="iconPath('desktopapp.png')"
          talent="Desktop Applications"
        />
        <talent-card ref="api" :img="iconPath('API.png')" talent="APIs and libraries" />
        <talent-card ref="cicd" :img="iconPath('cicd.png')" talent="Setup CI/CD pipelines" />
      </div>
      <list-button
        @click="handleManualScroll(manualScrollDelta)"
        :disabled="currentTalent === talents.length - 1"
        :direction="'right'"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TalentCard from "@/components/TalentCard.vue";
import ListButton from "./ListButton.vue";

export default defineComponent({
  name: "MobileTalentsList",
  components: { TalentCard, ListButton },
  data: function () {
    return {
      manualScrollDelta: 250,
      talents: ["cloud", "architecture", "webapp", "desktopapp", "api", "cicd"],
      currentTalent: 0,
      scrollStart: 0,
      scrollEnd: 0,
    };
  },
  methods: {
    handleTouchStart: function (this: any, event: TouchEvent) {
      this.scrollStart = event.changedTouches[0].pageX;
    },
    handleTouchEnd: function (this: any, event: TouchEvent) {
      this.scrollEnd = event.changedTouches[0].pageX;
      const deltaY = this.scrollStart - this.scrollEnd;
      if (deltaY > 10 || deltaY < -10) this.handleScroll({ deltaY });
    },
    handleScroll: function (this: any, event: any) {
      this.currentTalent += event.deltaY > 0 ? 1 : -1;
      this.currentTalent = this.currentTalent < 0 ? 0 : this.currentTalent;
      this.currentTalent =
        this.talents.length === this.currentTalent ? this.currentTalent - 1 : this.currentTalent;
      this.$refs[this.talents[this.currentTalent]].$el.scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "nearest",
      });
    },
    handleManualScroll: function (this: any, delta: number) {
      this.handleScroll({ deltaY: delta });
    },
    iconPath: function (this: any, iconName: any) {
      const ctx = require.context("@/assets/icons/", false);
      return ctx("./" + iconName);
    },
  },
});
</script>

<style scoped>
.scroll_btn {
  @apply rounded-full 
    text-zinc-800 text-7xl text-center 
    w-16 h-16
    active:scale-75
    transition-all
    duration-300
    ease-linear;
}
</style>
