<template>
  <div :class="$isMobile() ? 'mobile_talent_card' : 'desktop_talent_card'">
    <img class="rounded-lg" :src="img" width="200" height="200" />
    <p
      :class="
        $isMobile()
          ? 'text-sm max-w-full text-wrap text-center indent-0'
          : 'text-center indent-0 pt-2'
      "
    >
      {{ talent }}
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "TalentCard",
  props: { img: String, talent: String },
});
</script>

<style scoped>
.mobile_talent_card {
  @apply flex flex-col 
    items-center 
    justify-between 
    bg-white 
    max-w-60 min-w-60 
    gap-y-4 
    p-2 
    rounded-lg;
}
.desktop_talent_card {
  @apply flex flex-col 
    items-center 
    justify-between 
    bg-inherit 
    max-w-72 min-w-72 
    gap-y-4 
    p-2 
    rounded-lg
    text-transparent
    hover:text-zinc-600
    hover:bg-white
    transition duration-500;
}
div.desktop_talent_card > img {
  @apply opacity-90 hover:opacity-100 transition-opacity duration-500;
}
</style>
