<template>
  <div class="flex flex-col items-center justify-center max-w-full">
    <h3 class="mb-4 text-2xl font-bold max-w-fit">Tools I use</h3>
    <div class="flex flex-row items-center justify-center gap-x-2">
      <list-button @click="handleManualScroll(-manualScrollDelta)" :disabled="0 === currentStack" />
      <div
        class="flex flex-row gap-x-20 p-0 border-zinc-600 min-w-60 max-w-60 max-h-fit overflow-x-hidden"
        @wheel.prevent="handleScroll($event)"
        @touchstart="handleTouchStart"
        @touchend="handleTouchEnd"
      >
        <mobile-tech-stack-card
          :ref="stack.name"
          v-for="stack in stacks"
          :key="stack"
          :stack="stack"
        />
      </div>
      <list-button
        @click="handleManualScroll(manualScrollDelta)"
        :disabled="currentStack === Object.keys(this.$refs).length - 1"
        :direction="'right'"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useTechnologiesStore } from "@/store/technologies/index";
import { mapState } from "pinia";
import MobileTechStackCard from "./MobileTechStackCard.vue";
import ListButton from "./ListButton.vue";

export default defineComponent({
  name: "MobileTechnologiesStacks",
  components: { MobileTechStackCard, ListButton },
  data: function () {
    return {
      currentStack: 0,
      manualScrollDelta: 250,
      scrollStart: 0,
      scrollEnd: 0,
    };
  },
  methods: {
    handleTouchStart: function (this: any, event: TouchEvent) {
      this.scrollStart = event.changedTouches[0].pageX;
    },
    handleTouchEnd: function (this: any, event: TouchEvent) {
      this.scrollEnd = event.changedTouches[0].pageX;
      const deltaY = this.scrollStart - this.scrollEnd;
      if (deltaY > 15 || deltaY < -15) this.handleScroll({ deltaY });
    },
    handleScroll: function (this: any, event: any) {
      const stacks = Object.keys(this.$refs);
      this.currentStack += event.deltaY > 0 ? 1 : -1;
      this.currentStack = this.currentStack < 0 ? 0 : this.currentStack;
      this.currentStack =
        stacks.length === this.currentStack ? this.currentStack - 1 : this.currentStack;
      this.$refs[stacks[this.currentStack]][0].$el.scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "nearest",
      });
    },
    handleManualScroll: function (this: any, delta: number) {
      this.handleScroll({ deltaY: delta });
    },
  },
  computed: {
    ...mapState(useTechnologiesStore, ["stacks"]),
  },
});
</script>
