<template>
  <div>
    <div class="flex flex-col gap-y-2 items-center">
      <input
        ref="search_input"
        class="search_bar"
        type="text"
        placeholder="Type here to search"
        v-model="searched"
      />
      <div class="flex flex-row justify-between gap-x-4">
        <div>
          <input
            class="scale-125 accent-zinc-800"
            type="checkbox"
            id="fuzzy_search"
            v-model="fuzzy"
          />
          <label class="px-2" for="fuzzy_search">fuzzy</label>
        </div>
        <div>
          <input
            class="scale-125 accent-zinc-800"
            type="checkbox"
            id="match_case"
            v-model="matchCase"
          />
          <label class="px-2" for="match_case">match case</label>
        </div>
      </div>
    </div>
    <technologies-table
      :headers="headers"
      :technologies="technologies"
      :searched="searched"
      :fuzzy="fuzzy"
      :matchCase="matchCase"
      class="animate-tableappearance"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TechnologiesTable from "@/components/TechnologiesTable.vue";
import { useTechnologiesStore } from "@/store/technologies/index";
import { mapState, storeToRefs } from "pinia";

export default defineComponent({
  name: "TechnologiesView",
  components: {
    TechnologiesTable,
  },
  mounted: function (this: any) {
    this.$refs.search_input.scrollIntoView({
      behavior: "smooth",
      inline: "start",
      block: "nearest",
    });
  },
  data() {
    return {
      searched: storeToRefs(useTechnologiesStore()).searched,
      fuzzy: false,
      matchCase: false,
    };
  },
  computed: {
    ...mapState(useTechnologiesStore, ["headers", "technologies"]),
  },
});
</script>

<style scoped>
.search_bar {
  @apply w-fit
    text-center
    bg-transparent
    placeholder-zinc-500
    p-2
    border-b
    border-zinc-500

    focus:outline-none
    focus:placeholder-transparent
    focus:border-black

    animate-show-and-slide-from-right-1s;
}
</style>
