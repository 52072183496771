<template>
  <div class="flex flex-col gap-y-4">
    <h3 class="text-2xl font-bold">What I can do for you</h3>
    <div class="grid grid-cols-3 grid-rows-auto gap-4">
      <talent-card
        :img="iconPath('3clouds.webp')"
        talent=" Cloud Applications and adjacent infrastructure(databases, pipelines, message queues, key vaults, kubernetes cluster, etc.) "
      />
      <talent-card
        :img="iconPath('microservices.webp')"
        talent="Monolith and microservices architecture"
      />
      <talent-card :img="iconPath('webapp.png')" talent="Web Applications" />
      <talent-card :img="iconPath('desktopapp.png')" talent="Desktop Applications" />
      <talent-card :img="iconPath('API.png')" talent="APIs and libraries" />
      <talent-card :img="iconPath('cicd.png')" talent="Setup CI/CD pipelines" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TalentCard from "@/components/TalentCard.vue";

export default defineComponent({
  name: "TalentsList",
  components: { TalentCard },
  methods: {
    iconPath: function (this: any, iconName: any) {
      const ctx = require.context("@/assets/icons/", false);
      return ctx("./" + iconName);
    },
  },
});
</script>
