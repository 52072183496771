import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pb-2 flex flex-col items-center gap-y-56" }
const _hoisted_2 = { class: "flex flex-col gap-y-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_contacts_title = _resolveComponent("contacts-title")!
  const _component_description = _resolveComponent("description")!
  const _component_talents_list = _resolveComponent("talents-list")!
  const _component_technologies_stack = _resolveComponent("technologies-stack")!
  const _component_companies_list = _resolveComponent("companies-list")!
  const _component_feedbacks_list = _resolveComponent("feedbacks-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_contacts_title, {
        name: _ctx.name,
        personal: _ctx.personal,
        links: _ctx.links
      }, null, 8, ["name", "personal", "links"]),
      _createVNode(_component_description, {
        id: "description",
        info: _ctx.description
      }, null, 8, ["info"])
    ]),
    _createVNode(_component_talents_list),
    _createVNode(_component_technologies_stack),
    _createVNode(_component_companies_list, { companies: _ctx.companies }, null, 8, ["companies"]),
    _createVNode(_component_feedbacks_list, { feedbacks: _ctx.feedbacks }, null, 8, ["feedbacks"])
  ]))
}