<template>
  <nav v-if="!$isMobile()">
    <router-link class="router-link" to="/projects">Projects</router-link>
    <router-link class="router-link" to="/">About me</router-link>
    <router-link class="router-link" to="/tech">Technologies</router-link>
  </nav>
  <router-view v-if="!$isMobile()" class="pt-10" />
  <mobile-main v-else />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MobileMain from "@/components/MobileMain.vue";

export default defineComponent({ name: "App", components: { MobileMain } });
</script>

<style>
* {
  @apply font-custom;
}

#app {
  @apply text-center
    text-zinc-800
    h-screen
    m-0
    p-0
    overflow-y-auto
    overflow-x-hidden
    scroll-smooth
    bg-gradient-to-br
    from-zinc-400
    to-transparent;
}

nav {
  @apply flex 
   justify-center 
   pt-10 h-32;
}

nav > a {
  @apply text-3xl
    mx-5
    font-bold
    rounded-full
    border
    border-transparent
    transition-all
    duration-700
    ease-in;
}

nav > a:hover {
  @apply rounded-full
    shadow-[0_35px_60px_-15px_rgba(10,10,10,0.7)]
    border
    border-zinc-400
    bg-zinc-600
    text-zinc-300
    transition-all
    duration-500
    ease-linear;
}

nav > a.router-link-exact-active {
  @apply bg-zinc-800
    rounded-full
    text-white
    transition
    duration-700
    ease-in-out
    pointer-events-none;
}

.router-link {
  @apply w-72 max-w-72 h-fit py-2;
}
</style>
