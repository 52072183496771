<template>
  <div class="block w-auto">
    <div
      class="flex flex-row justify-center gap-x-20"
      v-for="(project, index) in projects"
      :key="index"
    >
      <img class="min-w-[500px] max-w-[500px] rounded-xl" :src="project.gif" />
      <div class="flex flex-col">
        <a :href="project.link">
          <h2>{{ project.name }}</h2>
        </a>
        <pre class="text-left text-wrap max-w-2xl">{{ project.description }}</pre>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { PersonalProject } from "@/store/projects/personal";

export default defineComponent({
  name: "PersonalProjects",
  props: { projects: { type: Array as PropType<PersonalProject[]>, required: true } },
});
</script>

<style scoped>
* {
  @apply font-custom;
}
a {
  @apply flex text-blue-600 hover:underline;
}
img {
  @apply max-w-60 inline mb-2;
}
h2 {
  @apply text-2xl font-bold;
}
</style>
