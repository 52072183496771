import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_mobile_main = _resolveComponent("mobile-main")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.$isMobile())
      ? (_openBlock(), _createElementBlock("nav", _hoisted_1, [
          _createVNode(_component_router_link, {
            class: "router-link",
            to: "/projects"
          }, {
            default: _withCtx(() => [
              _createTextVNode("Projects")
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, {
            class: "router-link",
            to: "/"
          }, {
            default: _withCtx(() => [
              _createTextVNode("About me")
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, {
            class: "router-link",
            to: "/tech"
          }, {
            default: _withCtx(() => [
              _createTextVNode("Technologies")
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (!_ctx.$isMobile())
      ? (_openBlock(), _createBlock(_component_router_view, {
          key: 1,
          class: "pt-10"
        }))
      : (_openBlock(), _createBlock(_component_mobile_main, { key: 2 }))
  ], 64))
}