<template>
  <div class="flex flex-col gap-y-4 bg-white rounded-xl max-h-fit min-w-60 max-w-60">
    <p class="pt-2 text-2xl font-bold">{{ stack.name }}</p>
    <div
      :class="
        stack.technologies.length < 6
          ? 'grid grid-cols-1 grid-rows-6'
          : 'grid grid-cols-2 grid-rows-6 gap-x-4'
      "
    >
      <div class="flex flex-col items-center p-2" v-for="t in stack.technologies" :key="t">
        <img class="min-w-[32px]" :src="iconPath(t.icon)" width="32px" height="32px" />
        <p class="text-sm">
          {{ t.name }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { TechnologyStack } from "@/store/technologies/technology_stack";

export default defineComponent({
  name: "MobileTechStackCard",
  props: { stack: Object as PropType<TechnologyStack> },
  methods: {
    iconPath: function (this: any, iconName: any) {
      const ctx = require.context("@/assets/icons/", false, /\.svg$/);
      return ctx("./" + iconName + ".svg");
    },
  },
});
</script>
