<template>
  <div class="flex flex-col gap-y-8">
    <h3 class="text-2xl font-bold">Companies I worked with</h3>
    <div class="flex flex-row gap-x-4 px-4 items-center content-center justify-center">
      <div class="grid grid-cols-3 grid-rows-auto gap-10">
        <company-card v-for="company in companies" :key="company" :info="company" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import CompanyCard from "@/components/CompanyCard.vue";
import { Company } from "@/store/about/company";

export default defineComponent({
  name: "CompaniesList",
  components: { CompanyCard },
  props: { companies: Array as PropType<Array<Company>> },
});
</script>
