<template>
  <div class="flex flex-col gap-y-8">
    <h3 class="text-2xl font-bold">Clients feedback</h3>
    <div class="grid grid-cols-2 gap-y-24 gap-x-2 pb-12">
      <template v-for="feedback in feedbacks" :key="feedback">
        <div class="flex flex-col gap-y-2 items-center">
          <img class="max-w-40 max-h-lg rounded-xl" :src="feedback.photo" />
          <p class="text-wrap max-w-sm">
            {{ feedback.position }}
          </p>
          <a class="ref-in-paragraph max-w-sm" :href="feedback.linkedin">{{ feedback.linkedin }}</a>
          <a
            class="ref-in-paragraph max-w-sm"
            v-if="feedback.email"
            :href="'mailto:' + feedback.email"
            >{{ feedback.email }}</a
          >
        </div>
        <p class="text-base max-w-xl font-custom text-justify text-wrap indent-10">
          {{ feedback.text }}
        </p>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Feedback } from "@/store/about/feedback";

export default defineComponent({
  name: "FeedbacksList",
  props: { feedbacks: Array as PropType<Array<Feedback>> },
});
</script>
