import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center gap-y-40" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_contacts_title = _resolveComponent("contacts-title")!
  const _component_description = _resolveComponent("description")!
  const _component_mobile_talents_list = _resolveComponent("mobile-talents-list")!
  const _component_mobile_technologies_stacks = _resolveComponent("mobile-technologies-stacks")!
  const _component_mobile_companies_list = _resolveComponent("mobile-companies-list")!
  const _component_mobile_feedbacks_list = _resolveComponent("mobile-feedbacks-list")!
  const _component_mobile_personal_projects = _resolveComponent("mobile-personal-projects")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "flex flex-col items-center justify-center max-w-full",
      onTouchstart: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
      onTouchend: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
    }, [
      _createVNode(_component_contacts_title, {
        ref: "contactsTitle",
        class: "pt-8",
        name: _ctx.name,
        personal: _ctx.personal,
        links: _ctx.links
      }, null, 8, ["name", "personal", "links"]),
      _createVNode(_component_description, { class: "pt-8 text-sm" })
    ], 32),
    _createVNode(_component_mobile_talents_list, {
      ref: "talents",
      onTouchstart: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"])),
      onTouchend: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"]))
    }, null, 512),
    _createVNode(_component_mobile_technologies_stacks, {
      ref: "stacks",
      onTouchstart: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"])),
      onTouchend: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["stop"]))
    }, null, 512),
    _createVNode(_component_mobile_companies_list, {
      ref: "companies",
      companies: _ctx.companies,
      onTouchstart: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["stop"])),
      onTouchend: _cache[7] || (_cache[7] = _withModifiers(() => {}, ["stop"]))
    }, null, 8, ["companies"]),
    _createVNode(_component_mobile_feedbacks_list, {
      ref: "feedbacks",
      feedbacks: _ctx.feedbacks,
      onTouchstart: _cache[8] || (_cache[8] = _withModifiers(() => {}, ["stop"])),
      onTouchend: _cache[9] || (_cache[9] = _withModifiers(() => {}, ["stop"]))
    }, null, 8, ["feedbacks"]),
    _createVNode(_component_mobile_personal_projects, {
      ref: "projects",
      onTouchstart: _cache[10] || (_cache[10] = _withModifiers(() => {}, ["stop"])),
      onTouchend: _cache[11] || (_cache[11] = _withModifiers(() => {}, ["stop"]))
    }, null, 512)
  ]))
}