<template>
  <div class="animate-appearance-2s">
    <img class="max-w-60 inline rounded-full" alt="Personal photo" src="@/assets/photo.jpg" />
    <h1 class="text-3xl font-bold pt-10">{{ name }}</h1>
    <personal-info :info="personal" :links="links" :class="$isMobile() ? 'mobile' : 'desktop'" />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Personal } from "@/store/about/personal";
import { Links } from "@/store/about/links";
import PersonalInfo from "@/components/PersonalInfo.vue";

export default defineComponent({
  name: "ContactsTitle",
  components: { PersonalInfo },
  props: {
    name: String,
    personal: Object as PropType<Personal>,
    links: Object as PropType<Links>,
  },
});
</script>

<style scoped>
.desktop {
  @apply inline-block pt-4 animate-show-and-slide-from-right-1s;
}
.mobile {
  @apply text-sm inline-block pt-4 animate-show-and-slide-from-right-1s;
}
</style>
