import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2dc86bcc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col items-center min-w-[200px] max-w-[200px]" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "text-2xl font-bold max-w-[200px]" }
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      class: _normalizeClass(_ctx.$isMobile() ? 'mobile_company_preview' : 'company_preview'),
      href: _ctx.info.url,
      target: "_blank"
    }, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.info.title), 1),
      (_ctx.info.title === 'WirePulse')
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "max-w-[200px] rounded-lg py-16",
            src: _ctx.info.imgUrl
          }, null, 8, _hoisted_4))
        : (_openBlock(), _createElementBlock("img", {
            key: 1,
            class: "min-h-[150px] max-w-[200px] rounded-lg",
            src: _ctx.info.imgUrl
          }, null, 8, _hoisted_5))
    ], 10, _hoisted_2)
  ]))
}