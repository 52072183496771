<template>
  <div class="pb-2 flex flex-col items-center gap-y-56">
    <div class="flex flex-col gap-y-10">
      <contacts-title :name="name" :personal="personal" :links="links" />
      <description id="description" :info="description" />
    </div>
    <talents-list />
    <technologies-stack />
    <companies-list :companies="companies" />
    <feedbacks-list :feedbacks="feedbacks" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ContactsTitle from "@/components/ContactsTitle.vue";
import Description from "@/components/Description.vue";
import { useAboutStore } from "@/store/about/about";
import { mapState } from "pinia";
import CompaniesList from "@/components/CompaniesList.vue";
import FeedbacksList from "@/components/FeedbacksList.vue";
import TalentsList from "@/components/TalentsList.vue";
import TechnologiesStack from "@/components/TechnologiesStack.vue";

export default defineComponent({
  name: "AboutView",
  components: {
    ContactsTitle,
    Description,
    CompaniesList,
    FeedbacksList,
    TalentsList,
    TechnologiesStack,
  },
  computed: {
    ...mapState(useAboutStore, ["personal", "links", "name", "companies", "feedbacks"]),
  },
});
</script>
