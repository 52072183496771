import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d6a0ec92"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$isMobile() ? 'mobile_talent_card' : 'desktop_talent_card')
  }, [
    _createElementVNode("img", {
      class: "rounded-lg",
      src: _ctx.img,
      width: "200",
      height: "200"
    }, null, 8, _hoisted_1),
    _createElementVNode("p", {
      class: _normalizeClass(
        _ctx.$isMobile()
          ? 'text-sm max-w-full text-wrap text-center indent-0'
          : 'text-center indent-0 pt-2'
      )
    }, _toDisplayString(_ctx.talent), 3)
  ], 2))
}