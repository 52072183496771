import { defineStore } from "pinia";
import technologies from "../../assets/technologies.json";
import stacks from "../../assets/stacks.json";
import { TechnologyStack } from "./technology_stack";

interface State {
  headers: string[];
  technologies: object;
  searched: string;
  stacks: TechnologyStack[];
}

export const useTechnologiesStore = defineStore("technologies", {
  state: (): State => {
    return { searched: "", ...technologies, stacks };
  },
});
