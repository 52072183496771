import { defineStore } from "pinia";
import { Personal } from "./personal";
import { Links } from "./links";
import { Company } from "./company";
import about from "../../assets/about.json";
import companiesList from "../../assets/companies.json";
import feedbacksList from "../../assets/feedbacks.json";
import { Feedback } from "./feedback";

interface State {
  personal: Personal;
  links: Links;
  name: string;
  companies: Company[];
  feedbacks: Feedback[];
}

export const useAboutStore = defineStore("about", {
  state: (): State => {
    return { ...about, companies: companiesList, feedbacks: feedbacksList };
  },
});
