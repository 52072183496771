<template>
  <div class="flex flex-col items-center min-w-[200px] max-w-[200px]">
    <a
      :class="$isMobile() ? 'mobile_company_preview' : 'company_preview'"
      :href="info.url"
      target="_blank"
    >
      <p class="text-2xl font-bold max-w-[200px]">{{ info.title }}</p>
      <img
        v-if="info.title === 'WirePulse'"
        class="max-w-[200px] rounded-lg py-16"
        :src="info.imgUrl"
      />
      <img v-else class="min-h-[150px] max-w-[200px] rounded-lg" :src="info.imgUrl" />
    </a>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Company } from "@/store/about/company";

export default defineComponent({
  name: "CompanyCard",
  props: { info: Object as PropType<Company> },
});
</script>

<style scoped>
.company_preview {
  @apply p-2 
    border-2 
    border-transparent 
    rounded-lg
    text-zinc-800
    flex flex-col items-center
    hover:border-zinc-400 
    hover:bg-zinc-400 
    hover:shadow-[0px_0px_5px_10px_rgba(82,82,91,0.4)]
    hover:text-zinc-200
    active:scale-75
    transition-all
    duration-500
    ease-linear;
}
.mobile_company_preview {
  @apply border-2 
    border-transparent 
    rounded-lg
    text-zinc-800
    flex flex-col items-center
    transition-all
    duration-500
    ease-linear;
}
</style>
